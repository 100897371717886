import LocaleContainer from '@/components/LocaleContainer';
import LegalContainer from '@/components/LegalContainer';
import RealTimeTrackingContainer from '@/components/rtt/RealTimeTrackingContainer';

const LandingContainerI = () => import('@/components/LandingContainer');

const routes = [
  {
    component: LocaleContainer,
    path: '/:countryOrTenantId/:locale',
    name: 'localeLanding',
    props: true,
    children: [
      ...[
        'impressum',
        'imprint',
        'impronta',
        'felhasználási-szabályzat',
        'mentions-légales',
        'tisk',
        'nadruk',
        'aviso-legal',
        'bedrijfsinformatie',
        'juridische-informatie',
        'odtlačok',
        'юридическая-информация'
      ].map(path => ({
        component: LegalContainer,
        name: `imprint.${path}`,
        path
      })),
      ...[
        'adatkezelesi-tajekoztato',
        'soukromi',
        'datenschutz',
        'privacy',
        'confidentialite',
        'privacidad',
        'prywatność',
        'конфиденциальность',
        'ochrana-osobných-údajov'
      ].map(path => ({
        component: LegalContainer,
        name: `privacy.${path}`,
        path
      })),
      ...[
        'kontakt',
        'contact',
        'contatto',
        'kapcsolat',
        'contatti',
        'contacto',
        'контакт'
      ].map(path => ({
        component: LegalContainer,
        name: `contact.${path}`,
        path
      })),
      {
        component: LandingContainerI,
        name: 'landing',
        path: ''
      },
      {
        component: RealTimeTrackingContainer,
        name: 'realTimeTracking',
        path: ':trackingId/:postcode?',
        props: true
      }
    ]
  },
  {
    component: LandingContainerI,
    name: 'mainLanding', // adapt LandingContainer if you change name
    path: '/'
  }
];

export default routes;

import locale from './locales.js';
import countryCode from '@/config/isoCountryCodes.js';

const country = (countryCode, name, defaultLocale, supportedLocales) => ({
  name,
  defaultLocale,
  supportedLocales,
  code: countryCode
});

export default Object.fromEntries(
  [
    country(countryCode.AT, 'Österreich', locale['de-AT'], [
      locale.de,
      locale['de-AT'],
      locale.en
    ]),
    country(countryCode.BE, 'België, Belgique', locale['nl-BE'], [
      locale.de,
      locale.en,
      locale['fr-BE'],
      locale['nl-BE']
    ]),
    country(countryCode.CA, 'Canada', locale.en, [locale.en, locale.fr]),
    country(countryCode.CZ, 'Česká republika', locale.cs, [
      locale.cs,
      locale.en
    ]),
    country(countryCode.DE, 'Deutschland', locale.de, [locale.de, locale.en]),
    country(countryCode.ES, 'España', locale.es, [locale.en, locale.es]),
    country(countryCode.FR, 'France', locale.fr, [locale.en, locale.fr]),
    country(countryCode.HU, 'Magyarország', locale.hu, [locale.en, locale.hu]),
    country(countryCode.IE, 'Éire', locale.en, [locale.en]),
    country(countryCode.IT, 'Italia', locale.it, [locale.en, locale.it]),
    country(countryCode.NL, 'Nederland', locale.nl, [locale.en, locale.nl]),
    country(countryCode.PL, 'Polska', locale.pl, [locale.en, locale.pl]),
    country(countryCode.SK, 'Slovenská republika', locale.sk, [
      locale.en,
      locale.sk
    ]),
    country(countryCode.UK, 'United Kingdom', locale.en, [locale.en]),
    country(countryCode.US, 'United States of America', locale.en, [
      locale.en,
      locale['en-US']
    ])
  ].map(country => [country.code, country])
);

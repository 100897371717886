<template>
  <v-card
    :class="[
      'post-code-input',
      'pt-10',
      'pt-md-0',
      $rttI18n.isGlsItSelected ? 'post-code-input-shadowed' : ''
    ]"
    v-if="!trackingData.toAddress"
  >
    <div class="post-code-input-form v-col-12">
      <p class="desc" v-if="isServiceTypePickup">
        {{ $t('pickupAddress.description') }}
      </p>
      <p class="desc" v-else>{{ $t('deliveryAddress.description') }}</p>
      <v-form
        v-on:submit.prevent="wrapperLoadAdditionalTrackingData"
        :ref="postcodeForm"
        v-model="valid"
      >
        <v-text-field
          bg-color="#fff"
          density="compact"
          :label="$t('generic.postcode')"
          variant="outlined"
          required
          :rules="postcodeRules"
          v-model="postcode"
          :type="
            configGetter($rttI18n.tenantId, themeId).postCodeTextInput
              ? 'text'
              : 'tel'
          "
        ></v-text-field>
        <GDPRConsentComponent />
        <div>
          <v-btn
            :block="true"
            v-on:click.prevent="wrapperLoadAdditionalTrackingData"
            class="bg-primary"
            :disabled="!valid || !hasGdprConsent($rttI18n.tenantId)"
            type="submit"
            >{{ $t('deliveryAddress.buttonLabel') }}</v-btn
          >
        </div>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import GDPRConsentComponent from '@/components/GDPRConsentComponent';
import { configGetter } from '@/config/configGetter';
import { getUtmSource } from '@/router/queryParam.js';

export default {
  name: 'PostCodeInputComponent',
  components: {
    GDPRConsentComponent
  },
  data() {
    return {
      postcode: null,
      postcodeForm: null,
      postcodeRules: [v => !!v || this.$t('inputs.rules.postcode')],
      valid: false,
      configGetter
    };
  },
  computed: {
    ...mapState(['trackingData', 'themeId']),
    ...mapGetters(['hasGdprConsent', 'isServiceTypePickup'])
  },
  methods: {
    ...mapActions(['loadAdditionalTrackingData']),
    wrapperLoadAdditionalTrackingData() {
      const payload = {
        utmSource: getUtmSource(this.$route),
        tenantId: this.$rttI18n.tenantId,
        postcode: this.postcode,
        trackingId: this.trackingData.trackingId
      };
      this.loadAdditionalTrackingData(payload);
    }
  },
  watch: {
    trackingData(newTrackingData) {
      if (!newTrackingData.toAddress) {
        this.postcode = null;
      }
    }
  }
};
</script>

<style scoped>
/* the component is displayed above other components */
.post-code-input {
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  width: 100%;
  z-index: 2;
  min-height: 100%;
}

.post-code-input-shadowed {
  background-color: rgba(255, 255, 255, 1);

  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;

  transition: All 0.2s ease;
  -webkit-transition: All 0.2s ease;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border-bottom: 3px solid transparent;
}
.post-code-input-shadowed:hover {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.35);
  border-bottom: 3px solid #ffd100;
}
.post-code-input-form {
  background-color: transparent;
  border: unset;
}
.desc {
  font-weight: 500;
  text-align: center;
}
</style>

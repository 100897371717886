<template>
  <div
    :class="['px-0', isBelowMobileBreakpoint ? 'py-2' : 'py-4']"
    v-if="adData !== null && !$store.$isWidget"
  >
    <div v-if="isBelowMobileBreakpoint">
      <AdItem v-if="adItems.length === 1" :adData="adItems[0]"></AdItem>
      <v-carousel
        v-if="adItems.length > 1"
        height="auto"
        cycle
        hide-delimiter-background
        show-arrows="hover"
      >
        <v-carousel-item v-for="adItem in adItems" :key="adItem.banner_id">
          <AdItem :adData="adItem"></AdItem>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div v-else>
      <v-row dense class="flex-nowrap">
        <v-col
          :cols="12 / Math.max(adItems.length, 1)"
          v-for="adItem in adItems"
          :key="adItem.banner_id"
        >
          <AdItem :adData="adItem"></AdItem>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AdItem from './AdItem';

export default {
  name: 'AdComponent',
  components: {
    AdItem
  },
  props: {
    adData: {
      default: null
    }
  },
  computed: {
    ...mapState(['isBelowMobileBreakpoint']),
    adItems() {
      if (!this.adData) return [];
      return Array.isArray(this.adData) ? this.adData : [this.adData];
    }
  }
};
</script>

<style scoped>
.videoButton {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('/video-play-button.svg');
  background-position: center;
}
</style>
<style>
.v-carousel {
  padding-bottom: 45px;
}
</style>

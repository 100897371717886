<template>
  <v-col
    class="pa-0"
    v-if="
      (fromAddress || toAddress) &&
      !(
        configGetter($rttI18n.tenantId, themeId).disableFromAddress &&
        configGetter($rttI18n.tenantId, themeId).disableToAddress
      )
    "
  >
    <div :class="isBelowMobileBreakpoint ? 'py-2' : 'py-4'">
      <span class="font-weight-bold">{{ $t(addressHeaderKey) }}</span>
    </div>
    <v-card
      v-if="!configGetter($rttI18n.tenantId, themeId).disableFromAddress"
      class="card_small text-body-2 leading-7 d-flex v-col-12"
    >
      <p class="v-col-6 ma-0 pa-0" v-if="fromAddress">
        <span class="font-weight-bold">{{ $t('generic.consignor') }}</span>
        <br />
        {{ fromAddress.name }}<br />
        {{ fromAddress.street }}
        {{ fromAddress.number }}
        <br />
        {{ fromAddress.postcode }}
        {{ fromAddress.city }}
      </p>
      <p
        class="flex v-col-6 ma-0 pa-0 pl-4"
        v-if="
          toAddress &&
          !configGetter($rttI18n.tenantId, themeId).disableToAddress
        "
      >
        <span class="font-weight-bold">{{ $t('generic.consignee') }}</span>
        <br />
        {{ toAddress.name }}
        <br />
        {{ toAddress.street }} {{ toAddress.number }}
        <br />
        {{ toAddress.postcode }} {{ toAddress.city }}
      </p>
    </v-card>
    <v-card
      v-else-if="configGetter($rttI18n.tenantId, themeId).disableFromAddress"
      class="card_small text-body-2"
    >
      <div v-if="toAddress">
        <div class="font-weight-bold">{{ toAddress.name }}</div>
        {{ toAddress.street }} {{ toAddress.number
        }}{{ toAddress.postcode ? `, ${toAddress.postcode}` : '' }}
        {{ toAddress.city }}
      </div>
    </v-card>
  </v-col>
</template>

<script>
import { configGetter } from '@/config/configGetter';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'DeliveryAddressComponent',
  data() {
    return {
      configGetter
    };
  },
  computed: {
    ...mapState(['themeId', 'trackingData', 'isBelowMobileBreakpoint']),
    ...mapGetters(['isServiceTypePickup']),
    fromAddress() {
      return this.isServiceTypePickup
        ? this.trackingData.toAddress
        : this.trackingData.fromAddress;
    },
    toAddress() {
      return this.isServiceTypePickup
        ? this.trackingData.fromAddress
        : this.trackingData.toAddress;
    },
    addressHeaderKey() {
      return configGetter(this.$rttI18n.tenantId, this.themeId)
        .disableFromAddress
        ? 'generic.consignee'
        : 'deliveryAddress.header';
    }
  }
};
</script>

<style scoped></style>

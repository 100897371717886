export const dialogs = {
  CUSTOMIZE: 'CUSTOMIZE',
  DELIVERY_PERIOD: 'DELIVERY_PERIOD',
  DROP_OFF: 'DROP_OFF',
  NEIGHBOUR: 'NEIGHBOUR',
  NOTIFICATION_SUBSCRIPTION: 'NOTIFICATION_SUBSCRIPTION',
  PARCEL_SHOP: 'PARCEL_SHOP',
  PUSH_NOTIFICATION_SUBSCRIPTION: 'PUSH_NOTIFICATION_SUBSCRIPTION',
  TRUST_PILOT_CONSENT_DIALOG: 'TRUST_PILOT_CONSENT_DIALOG'
};

<template>
  <div>
    <div
      :style="currentTheme.disableTrackingId ? { visibility: 'hidden' } : null"
      v-if="!isBelowMobileBreakpoint && !$store.$isWidget"
      class="py-4"
    >
      <span class="font-weight-bold">{{ $t('generic.trackingId') }}</span
      >: {{ trackingData.trackingId }}
    </div>
    <AwsMapComponent
      :showLiveMap="getPrivateTrackingState"
      :trackingId="trackingData.trackingId"
    />
  </div>
</template>

<script>
import { configGetter } from '@/config/configGetter';
import { mapGetters, mapState } from 'vuex';
import {
  TRACKING_DELIVERY_STATE_CLOSE,
  TRACKING_DELIVERY_STATE_LAST,
  TRACKING_STATE_DETAIL_PARCEL_SHOP,
  TRACKING_STATE_SHADOWED
} from '@/store/constants';
import AwsMapComponent from '@/components/map/AwsMapComponent';

export default {
  name: 'MapContainer',
  components: {
    AwsMapComponent
  },
  data() {
    return {
      showLiveMap: undefined,
      parcelShopDialog: false,
      TRACKING_DELIVERY_STATE_CLOSE,
      TRACKING_STATE_DETAIL_PARCEL_SHOP,
      TRACKING_DELIVERY_STATE_LAST,
      TRACKING_STATE_SHADOWED,
      configGetter
    };
  },
  computed: {
    ...mapState(['trackingData', 'isBelowMobileBreakpoint']),
    ...mapGetters([
      'getThemeId',
      'getDeliveryStatusDetail',
      'getPrivateTrackingState',
      'getTrackingState'
    ]),
    currentTheme() {
      return this.configGetter(this.$rttI18n.tenantId, this.getThemeId);
    }
  }
};
</script>

<style scoped></style>
